import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VDivider } from 'vuetify/lib/components/VDivider';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VRow } from 'vuetify/lib/components/VGrid';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VContainer,[_c(VRow,{attrs:{"justify":"center"}},[_c(VCol,{attrs:{"md":"2"}},[_c('div',{staticClass:"text-h6"},[_c(VIcon,{attrs:{"left":""}},[_vm._v("mdi-arrow-right-bold-circle")]),_vm._v(" Aucun contrat contraignant ")],1),_c('div',{staticClass:"mt-6 text-subtitle-1"},[_vm._v(" En choississant "),_c('span',{staticClass:"font-weight-medium primary--text"},[_vm._v("monplanning")]),_vm._v(", vous n'êtes pas pieds et poings liés à un contrat fixe et contraignant. Vous arrêtez l'aventure quand vous voulez ! ")])]),_c(VCol,{staticClass:"shrink"},[_c(VDivider,{attrs:{"vertical":""}})],1),_c(VCol,{attrs:{"md":"2"}},[_c('div',{staticClass:"text-h6"},[_c(VIcon,{attrs:{"left":""}},[_vm._v("mdi-thumb-up")]),_vm._v("Période d'essai de 30 jours ")],1),_c('div',{staticClass:"mt-6 text-subtitle-1"},[_vm._v(" Parce que nous ne voulons pas que vous vous engagiez dans quelque chose qui ne vous convient pas, vous pouvez essayer "),_c('span',{staticClass:"font-weight-medium primary--text"},[_vm._v("monplanning")]),_vm._v(" pendant 30 jours gratuitement et sans engagement. ")])]),_c(VCol,{staticClass:"shrink"},[_c(VDivider,{attrs:{"vertical":""}})],1),_c(VCol,{attrs:{"md":"2"}},[_c('div',{staticClass:"text-h6"},[_c(VIcon,{attrs:{"left":""}},[_vm._v("mdi-gift")]),_vm._v("Parrainage")],1),_c('div',{staticClass:"mt-6 text-subtitle-1"},[_vm._v(" Vous êtes satisfait de "),_c('span',{staticClass:"font-weight-medium primary--text"},[_vm._v("monplanning")]),_vm._v(" et voulez le faire découvrir à un collègue, un collaborateur ou un ami ? En le parrainant, vous et votre filleul recevez un mois gratuit. ")])])],1),_c(VRow,{attrs:{"justify":"center"}},[_c(VCol,{staticClass:"text-center"},[_c(VBtn,{staticClass:"mt-8",attrs:{"outlined":"","color":"primary","to":_vm.$helpers.router.getAdminLink('register')}},[_vm._v("essayer 30 jours sans engagement")])],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }