
<template>
  <v-container>
    <v-row justify="center">
      <v-col md="2">
        <div class="text-h6">
          <v-icon left>mdi-arrow-right-bold-circle</v-icon> Aucun contrat
          contraignant
        </div>
        <div class="mt-6 text-subtitle-1">
          En choississant
          <span class="font-weight-medium primary--text">monplanning</span>,
          vous n'êtes pas pieds et poings liés à un contrat fixe et
          contraignant. Vous arrêtez l'aventure quand vous voulez !
        </div>
      </v-col>
      <v-col class="shrink"><v-divider vertical /></v-col>
      <v-col md="2">
        <div class="text-h6">
          <v-icon left>mdi-thumb-up</v-icon>Période d'essai de 30 jours
        </div>
        <div class="mt-6 text-subtitle-1">
          Parce que nous ne voulons pas que vous vous engagiez dans quelque
          chose qui ne vous convient pas, vous pouvez essayer
          <span class="font-weight-medium primary--text">monplanning</span>
          pendant 30 jours gratuitement et sans engagement.
        </div>
      </v-col>
      <v-col class="shrink"><v-divider vertical /></v-col>
      <v-col md="2">
        <div class="text-h6"><v-icon left>mdi-gift</v-icon>Parrainage</div>
        <div class="mt-6 text-subtitle-1">
          Vous êtes satisfait de
          <span class="font-weight-medium primary--text">monplanning</span>
          et voulez le faire découvrir à un collègue, un collaborateur ou un ami
          ? En le parrainant, vous et votre filleul recevez un mois gratuit.
        </div>
      </v-col>
    </v-row>
    <v-row justify="center">
      <v-col class="text-center">
        <v-btn
          outlined
          color="primary"
          class="mt-8"
          :to="$helpers.router.getAdminLink('register')"
          >essayer 30 jours sans engagement</v-btn
        >
      </v-col>
    </v-row>
  </v-container>
</template>
    
<script>
export default {
  components: {},
};
</script>