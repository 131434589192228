<template>
  <div>
    <v-container fluid class="pb-16 gradient">
      <v-row v-if="$helpers.display.isMobile" justify="center">
        <v-col sm="8" md="8">
          <v-img src="/static/image/home2.jpg" class="rounded-lg" contain></v-img>

          <h1 class="text-h3 font-weight-medium mt-6">
            Simplifiez votre quotidien avec un
            <span class="primary--text">planning de cours en ligne !</span>
          </h1>
          <div class="mt-4">
            Découvrez monplanning, une application dédiée aux professionnels
            dispensant des cours (sport, loisirs créatifs, ateliers, artisanat…)
            et aux formateurs.
          </div>
        </v-col>
      </v-row>
      <v-row v-else justify="center">
        <v-col md="5">
          <v-img src="/static/image/home2.jpg" class="rounded-lg" contain></v-img>
        </v-col>
        <v-col md="5">
          <h1 class="text-h3 font-weight-medium">
            Simplifiez votre quotidien avec un
            <span class="primary--text">planning de cours en ligne !</span>
          </h1>
          <div class="mt-4">
            Découvrez monplanning, une application dédiée aux professionnels
            dispensant des cours (sport, loisirs créatifs, ateliers, artisanat…)
            et aux formateurs.
          </div>
        </v-col>
      </v-row>
      <v-row justify="center" align="start" class="pt-16">
        <v-col sm="4" md="10" class="text-body-1">
          Très simple d’utilisation, pratique et sécurisé, découvrez enfin un
          planning de cours en ligne disposant de toutes les fonctionnalités
          nécessaires pour
          <span class="font-weight-bold"
            >gérer votre emploi du temps, visualiser les inscriptions, les
            abonnements, les paiements et communiquer rapidement avec vos élèves
            !</span
          >
          <div class="mt-4">
            <v-form ref="form" @submit.prevent="register()">
              <v-row no-gutters>
                <v-text-field
                  outlined
                  label="Entrez votre adresse e-mail"
                  v-model="email"
                  :rules="$helpers.rule.email()"
                  required
                  dense
                />
                <v-btn class="ml-1" color="primary" @click="register()"
                  >J'essaie gratuitement pendant 30 jours</v-btn
                ></v-row
              >
            </v-form>
            <div class="text-body-2 mt-1">
              Commencez aujourd'hui votre essai gratuit de 30 jours, sans
              engagement.
            </div>
          </div>
        </v-col>
        <!-- <v-col md="4">
          <lightbox-carousel />
        </v-col> -->
      </v-row>
    </v-container>

    <v-container class="mt-6 pb-12">
      <v-row justify="center" no-gutters>
        <v-col sm="12" md="8">
          <h2 class="text-h4 text-center">
            Un agenda en ligne qui vous facilite la vie au quotidien,
            <span class="primary--text">ça existe !</span>
          </h2>
        </v-col>
      </v-row>
      <v-row class="mt-4" justify="center">
        <v-col md="6" class="text-center">
          Conçu pour vous faire gagner du temps, monplanning est l'outil qu'il
          vous faut pour vous libérer l'esprit et vous concentrer sur le plus
          important. À savoir : votre activité, vos clients et vous-même !
        </v-col>
      </v-row>
      <v-row justify="center" class="pt-12">
        <v-col md="4">
          <h3>Gérez votre emploi du temps</h3>
          <p class="mt-3">
            Organisez facilement votre travail et ayez une vue claire sur votre
            planning de cours et le nombre de participants inscrits. Modifiez ou
            annulez un cours d’un simple clic et prévenez vos élèves dans la
            foulée grâce à un e-mail envoyé automatiquement !
          </p>
        </v-col>
        <v-col md="4">
          <v-img src="/static/image/calendar.png" class="rounded-lg" contain />
        </v-col>
      </v-row>
      <v-row justify="center" class="pt-12">
        <v-col md="4">
          <v-img src="/static/image/screenshot-dashboard2.png" class="rounded-lg" contain />
        </v-col>
        <v-col md="4">
          <h3>Consultez à tout moment l’état de votre activité</h3>
          <p class="mt-3">
            Vous pouvez, à tout moment, visualiser le nombre d’élèves inscrits à
            vos cours (vue quotidienne, hebdomadaire ou mensuelle), le nombre
            d’abonnements, le statut des paiements, etc. Profitez d’un suivi
            clair à travers un tableau de bord intuitif qui vous permettra
            d’analyser votre activité.
          </p>
        </v-col>
      </v-row>
      <v-row justify="center" class="pt-12">
        <v-col md="4">
          <h3>
            Communiquez rapidement grâce à un système d’e-mails automatisés !
          </h3>
          <p class="mt-3">
            Inscription d’un nouveau client, réservation ou désistement d’un
            participant, modification ou annulation d’un cours… Grâce au système
            d'e-mails automatisés de
            <span class="primary--text font-weight-medium">monplanning</span>,
            vous et vos clients ne manqueront aucune communication importante.
            Un parcours 100 % digital pour un gain de temps considérable !
          </p>
        </v-col>
        <v-col md="4">
          <v-img src="/static/image/email-new-booking.png" class="rounded-lg" contain />
        </v-col>
      </v-row>
    </v-container>

    <v-container class="mt-6 pb-12">
      <v-row justify="center" no-gutters>
        <v-col sm="12" md="8">
          <h2 class="text-h4 text-center">
            Un planning de cours en ligne pensé
            <span class="primary--text"
              >par les professionnels pour les professionnels !</span
            >
          </h2>
        </v-col>
      </v-row>
      <v-row justify="center" class="pt-4">
        <v-col sm="3" md="5">
          <div class="text-h6 text-center">Cet outil est pour vous si :</div>
          <ul class="feature mt-3">
            <li>
              Vous avez une passion et vous donnez cours (danse, fitness, yoga,
              poterie, couture, etc.) ;
            </li>
            <li>Vous êtes formateurs ;</li>
            <li>
              Vous voulez digitaliser la gestion quotidienne de votre activité ;
            </li>
            <li>
              Vous voulez présenter le planning de vos cours à vos clients et
              futurs clients sur votre page web monplanning ;
            </li>
            <li>
              Vous cherchez à toucher une clientèle de plus en plus connectée ;
            </li>
            <li>Vous désirez dynamiser et moderniser votre image ;</li>
            <li>
              Vous ne voulez plus utiliser Excel ou un agenda papier et mettre à
              jour constamment les informations ;
            </li>
            <li>
              Vous voulez facilement et à tout moment consulter l'état de votre
              activité (nombre de participants, statuts des paiements,
              planning…).
            </li>
          </ul>
        </v-col>
      </v-row>
    </v-container>

    <v-container class="mt-6 pb-12">
      <v-row justify="center" no-gutters>
        <v-col sm="12" md="8">
          <h2 class="text-h4 text-center">
            Un outil de gestion accessible partout et tout le temps
          </h2>
          <p class="mt-12">
            Nous recherchons toutes et tous la même chose : la sérénité et un
            équilibre sain entre vie professionnelle et vie personnelle. Pour
            cela, pas de temps à perdre !
          </p>
          <p>
            Voilà pourquoi notre outil est en ligne et accessible où que vous
            soyez et à tout moment. Connectez-vous sur votre ordinateur, sur
            votre tablette ou sur votre smartphone et accédez à votre interface
            de gestion sans plus jamais perdre une minute.
          </p>
          <p>
            Que vous soyez dans une salle de sport, un atelier ou sur une piste
            de danse, <span class="primary--text font-weight-bold">monplanning</span> s'adapte à vous !
          </p>
          <p>
            Vos clients vous emmènent partout avec eux et retrouvent facilement
            le planning de vos cours. Ils peuvent s’inscrire, acheter un
            abonnement ou réserver une séance et payer en ligne. Tout se fait
            très rapidement grâce à l’application !
          </p>
        </v-col>
      </v-row>
    </v-container>

    <v-container class="mt-6 pb-12">
      <v-row justify="center" no-gutters>
        <v-col sm="12" md="8">
          <h2 class="text-h4 text-center">
            Une application rapide à prendre en main et facile à utiliser
          </h2>
          <p class="mt-12">
            Quoi de plus agaçant que de perdre son temps à comprendre comment
            utiliser un outil censé faciliter votre quotidien ? Nous désirons
            justement vous éviter de vivre cette expérience.
            <span class="primary--text font-weight-bold">monplanning</span> est
            <span class="font-weight-bold">une application 100 % belge</span>
            conçue pour être rapide à prendre en main et facile à utiliser au
            quotidien.
          </p>
          <p>
            Nous mettons à votre disposition des supports d’aide pour assister
            vos premiers pas : centre d'aide, service de support par e-mail,
            assistance via notre chat en ligne… Nos professionnels peuvent même
            se déplacer si cela s’avère nécessaire !
          </p>
        </v-col>
      </v-row>
      <v-row justify="center">
        <v-col>
          <BenefitsQuickSummary />
        </v-col>
      </v-row>
    </v-container>

    <v-container fluid class="mb-6 mt-0 gradient-inverse">
      <v-row class="pt-4" justify="center">
        <v-col sm="6">
          <div class="text-h5">Vous avez des questions ?</div>
          <div class="text-h5 font-weight-medium primary--text">
            Nous y répondons !
          </div>
        </v-col>
      </v-row>
      <v-row justify="center" class="mt-6">
        <v-col sm="6">
          <QuickHelp />
        </v-col>
      </v-row>
      <v-row justify="center">
        <v-col sm="3">
          <v-btn :to="getLink('helpcenter')" color="primary"
            >Visiter notre centre d'aide</v-btn
          >
        </v-col>
        <v-col sm="3" class="text-right">
          <v-btn :to="getLink('contact')" color="primary">Nous contacter</v-btn>
        </v-col>
      </v-row>
      <v-row justify="center" class="mt-2"> </v-row>
    </v-container>
  </div>
</template>

<script>
import QuickHelp from "@/components/Public/HelpCenter/Components/QuickHelp";
// import LightboxCarousel from "@/components/Public/LightboxCarousel";
// import HomeTimeline from "@/components/Public/HomeTimeline";
// import FeaturesTimeline from "@/components/Public/FeaturesTimeline";
// import Testimonials from "@/components/Public/Testimonials.vue";
import BenefitsQuickSummary from "@/components/Public/BenefitsQuickSummary.vue";

export default {
  name: "Home",
  components: {
    QuickHelp,
    // LightboxCarousel,
    // HomeTimeline,
    // FeaturesTimeline,
    // Testimonials,
    BenefitsQuickSummary,
  },
  computed: {},
  data() {
    return {
      email: "",
    };
  },
  methods: {
    getLink(target) {
      return this.$helpers.router.getPublicLink(target);
    },
    register() {
      if (this.$refs.form.validate() && this.email) {
        this.$router.push(`/admin/register?email=${this.email}`);
      }
    },
  },
};
</script>