<template>
  <v-expansion-panels multiple v-model="panel">
    <v-expansion-panel v-for="(item, i) in items" :key="i" class="mb-4">
      <v-expansion-panel-header>
        <v-container fluid class="pa-0 ma-0">
          <v-row>
            <v-col v-if="!$helpers.display.isMobile" md="1">
              <v-icon class="mr-4" color="primary">mdi-help-circle</v-icon>
            </v-col>
            <v-col>
              <span
                class="font-weight-medium text-h6"
                v-html="item.title"
              ></span>
            </v-col>
          </v-row>
        </v-container>
      </v-expansion-panel-header>
      <v-expansion-panel-content>
        <span v-html="item.description"></span>
      </v-expansion-panel-content>
    </v-expansion-panel>
  </v-expansion-panels>
</template>


<script>
export default {
  name: "QuickHelp",
  components: {},
  data() {
    return {
      panel: [],
      items: [
        {
          title:
            "Pourquoi travailler avec monplanning plutôt qu'une autre application ?",
          description:
            "5 bonnes raisons : notre outil est étudié et conçu pour optimiser la gestion administrative des professionnels. Il est rapide à prendre en main et facile à utiliser. monplanning est accessible sur tous les navigateurs et adapté à tous les supports et écrans. Et pour finir, notre outil est entièrement sécurisé !",
        },
        {
          title: "30 jours d'essai gratuit, vraiment ?",
          description: `Nous ne voulons pas vous contraindre à vous abonner sans que vous soyez sûr(e) et certain(e) de vouloir utiliser monplanning. Pour ce faire, nous avons prévu une période d’essai de 30 jours afin que vous puissiez utiliser l'outil gratuitement et vous faire votre propre avis sur son efficacité. Pour commencer votre essai gratuit de 30 jours sur monplanning, rendez-vous sur <a href="/admin/register">la page d'inscription</a>.`,
        },
        {
          title: "L'informatique, ce n'est pas mon truc. Comment ça marche ?",
          description:
            "monplanning est conçu pour être rapide à prendre en main et facile à utiliser au quotidien, même si vous n'êtes pas un(e) as de l’informatique. Et si vous ne deviez pas comprendre quelque chose, nous mettons à votre disposition toute une panoplie de supports d’aide pour assister vos premiers pas : centre d'aide, service de support par e-mail, assistance via notre chat en ligne, ... Votre question ne restera pas longtemps sans réponse !",
        },
        {
          title:
            "Je viens de démarrer mon activité. Votre outil est-il adapté pour moi ?",
          description:
            "Bien évidemment ! Nous avons prévu une période d'essai de 30 jours pour démarrer votre activité et garantir le suivi de vos clients même si vous êtes indépendant(e) complémentaire.",
        },
        {
          title: "monplanning fonctionne-t-il sur Mac ?",
          description:
            "monplanning est conçu pour fonctionner sur tous les navigateurs web modernes tels que Google Chrome, Safari, Firefox, Opera et Edge. Dès lors, que vous soyez sur Windows, Mac, Linux, sur un ordinateur de bureau, un ordinateur portable, une tablette ou un smartphone, vous aurez toujours accès à votre outil !",
        },
      ],
    };
  },
};
</script>
