import { VCol } from 'vuetify/lib/components/VGrid';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VExpansionPanel } from 'vuetify/lib/components/VExpansionPanel';
import { VExpansionPanelContent } from 'vuetify/lib/components/VExpansionPanel';
import { VExpansionPanelHeader } from 'vuetify/lib/components/VExpansionPanel';
import { VExpansionPanels } from 'vuetify/lib/components/VExpansionPanel';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VRow } from 'vuetify/lib/components/VGrid';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VExpansionPanels,{attrs:{"multiple":""},model:{value:(_vm.panel),callback:function ($$v) {_vm.panel=$$v},expression:"panel"}},_vm._l((_vm.items),function(item,i){return _c(VExpansionPanel,{key:i,staticClass:"mb-4"},[_c(VExpansionPanelHeader,[_c(VContainer,{staticClass:"pa-0 ma-0",attrs:{"fluid":""}},[_c(VRow,[(!_vm.$helpers.display.isMobile)?_c(VCol,{attrs:{"md":"1"}},[_c(VIcon,{staticClass:"mr-4",attrs:{"color":"primary"}},[_vm._v("mdi-help-circle")])],1):_vm._e(),_c(VCol,[_c('span',{staticClass:"font-weight-medium text-h6",domProps:{"innerHTML":_vm._s(item.title)}})])],1)],1)],1),_c(VExpansionPanelContent,[_c('span',{domProps:{"innerHTML":_vm._s(item.description)}})])],1)}),1)}
var staticRenderFns = []

export { render, staticRenderFns }